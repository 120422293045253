<template>
  <UtilitiesErrorBoundary tag="Column" :show-fallback="true">
    <div :class="wrapperClasses" :style="{ backgroundColor: backgroundColor }">
      <RichTextRenderer
        v-if="document"
        :document="document"
        :node-renderers="nodeRenderers"
        :mark-renderers="markRenderers"
      />
    </div>
    <LinksContentfulLink v-if="id" :id="id" label="column" />
  </UtilitiesErrorBoundary>
</template>

<script>
  import RichTextRenderer from 'contentful-rich-text-vue-renderer';
  import { markRenderers, nodeRenderers } from '~/utils/contentful-helpers';

  export default {
    components: {
      RichTextRenderer,
    },
    props: {
      length: Number,
      column: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        document:
          this.column.fields && this.column.fields.content
            ? this.column.fields.content
            : '',
        classNames: this.column.fields.classes,
      };
    },
    computed: {
      authorWrapperClasses() {
        if (this.classNames !== '' && this.classNames !== undefined) {
          return this.classNames.join(' ');
        } else {
          return '';
        }
      },
      backgroundColor() {
        return this.column.fields.backgroundColor !== undefined
          ? this.column.fields.backgroundColor
          : null;
      },
      wrapperClasses() {
        const column = this.column.fields;
        const textAlign = column.textAlign
          ? ` text-${column.textAlign.toLowerCase()}`
          : '';
        const verticalAlignValues = {
          Center: 'self-center',
          Top: 'self-start',
          Bottom: 'self-end',
        };
        const verticalAlign = column.verticalAlign
          ? ` ${verticalAlignValues[column.verticalAlign]}`
          : '';
        const columnCount = () => {
          const gridDefault = 12;
          if (this.length) {
            return gridDefault / this.length;
          }
          console.warn('Missing column length');
          return gridDefault;
        };
        const grid = {
          desktop: column.widthDesktop
            ? ` md:w-${column.widthDesktop}/12`
            : ` md:w-${columnCount()}/12`,
          tablet: column.widthTablet ? ` sm:w-${column.widthTablet}/12` : '',
          mobile: column.widthMobile
            ? ` w-${column.widthMobile}/12`
            : ' w-12/12',
        };
        // purgecss: border-r border-l border-black md:border-r md:border-left md:border-black border-[#D9D9D9] md:border-[#D9D9D9]
        // purgecss: text-left text-center
        // purgecss: w-1/12 w-2/12 w-3/12 w-4/12 w-5/12 w-6/12 w-7/12 w-8/12 w-9/12 w-10/12 w-11/12 md:w-1/12 md:w-2/12 md:w-3/12 md:w-4/12 md:w-5/12 md:w-6/12 md:w-7/12 md:w-8/12 md:w-9/12 md:w-10/12 md:w-11/12 md:w-12/12 sm:w-1/12 sm:w-2/12 sm:w-3/12 sm:w-4/12 sm:w-5/12 sm:w-6/12 sm:w-7/12 sm:w-8/12 sm:w-9/12 sm:w-10/12 sm:w-11/12 sm:w-12/12
        const gridOffset = {
          desktop: column.offsetDesktop
            ? ` md:ml-${column.offsetDesktop}/12`
            : ' md:ml-0',
          tablet: column.offsetTablet
            ? ` sm:ml-${column.offsetTablet}/12`
            : ' sm:ml-0',
          mobile: column.offsetMobile ? ` ml-${column.offsetMobile}/12` : '',
        };
        // purgecss: ml-1/12 ml-2/12 ml-3/12 ml-4/12 ml-5/12 ml-6/12 ml-7/12 ml-8/12 ml-9/12 ml-10/12 ml-11/12 md:ml-1/12 md:ml-2/12 md:ml-3/12 md:ml-4/12 md:ml-5/12 md:ml-6/12 md:ml-7/12 md:ml-8/12 md:ml-9/12 md:ml-10/12 md:ml-11/12 sm:ml-1/12 sm:ml-2/12 sm:ml-3/12 sm:ml-4/12 sm:ml-5/12 sm:ml-6/12 sm:ml-7/12 sm:ml-8/12 sm:ml-9/12 sm:ml-10/12 sm:ml-11/12
        const mobileVisibility = {
          Hide: 'hidden md:block',
          Show: 'block md:hidden',
        };
        const tabletVisibility = {
          Hide: 'hidden md:block',
          Show: 'block md:hidden',
        };
        const desktopVisibility = {
          Hide: 'hidden lg:block',
          Show: 'block lg:hidden',
        };
        const visibility = {
          mobile: column.hideshowMobile
            ? ` ${mobileVisibility[column.hideshowMobile]}`
            : '',
          tablet: column.hideshowTablet
            ? ` ${tabletVisibility[column.hideshowTablet]}`
            : '',
          desktop: column.hideshowDesktop
            ? ` ${desktopVisibility[column.hideshowDesktop]}`
            : '',
        };
        return `column px-gap whitespace-pre-wrap ${this.authorWrapperClasses}${grid.desktop}${grid.tablet}${grid.mobile}${gridOffset.desktop}${gridOffset.tablet}${gridOffset.mobile}${textAlign}${verticalAlign}${visibility.mobile}${visibility.tablet}${visibility.desktop}`;
      },
    },
  };
</script>

<style lang="scss">
  :not(p) {
    @apply whitespace-normal;
  }
  .text-center {
    .video {
      margin: 0 auto;
    }
  }
  .column {
    &.psuedo-arrow-light-blue {
      @apply relative;

      margin-bottom: 0 !important;
      &::after {
        @apply absolute bottom-[-60px] h-0 w-0 translate-x-[-50%];

        border-left: 90px solid transparent;
        border-right: 90px solid transparent;
        border-top: 90px solid #f0f6fe;
        @screen md {
          @apply absolute right-[-90px] top-[50%] translate-y-[-50%];

          border-top: 60px solid transparent;
          border-bottom: 60px solid transparent;
          border-left: 60px solid #f0f6fe;
          border-right: none;
          @apply mb-1;
        }

        content: '';
        clear: both;
      }
    }
    p a u {
      text-decoration: none;
    }

    ul {
      list-style: unset;
      margin-left: 20px;

      p {
        margin-bottom: 0;
      }
    }

    &:not(:last-of-type) {
      @media (max-width: theme('screens.md')) {
        @apply mb-1;
      }
    }
    p:empty:last-of-type {
      margin: 0;
      @apply hidden;
    }
  }

  .venture-col {
    @screen sm-max {
      @apply pb-[30px];
    }
  }
</style>
