import { generateUUID } from '~/utils/html-helpers';

export function sendCSAnalyticsEvent(flagKey: string, value: any) {
  const context = {
    kind: 'user',
    key: generateUUID(),
    name: 'website_user',
  };

  /**
   * Calls the ContentSquare integration handler with the flag evaluation data.
   *
   * Arguments:
   * flagKey (string): The key of the evaluated flag.
   * value (any): The value of the served flag variation.
   * context (object): The evaluation context used for the flag evaluation, containing properties:
   *    - kind (string): The kind of context (e.g., "user", "organization").
   *    - key (string): The unique key for the context (e.g., user ID).
   *    - name (string): The name associated with the context (e.g., user name).
   */
  function callCShandler() {
    window.CS_CONF.integrations_handler.launchdarkly(flagKey, value, context);
  }

  if (
    window.CS_CONF &&
    window.CS_CONF.integrations_handler &&
    window.CS_CONF.integrations_handler.launchdarkly
  ) {
    callCShandler();
  } else {
    window.addEventListener('Contentsquare_LD_Integration_Launch', () => {
      callCShandler();
    });
  }
}
